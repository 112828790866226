import * as React from "react"
import Layout from "../../components/layout"
import "@fontsource/raleway/300.css"
import Hero from '../../components/hero'
import { Link } from 'gatsby'
import Header from '../../components/header'
import AdBanner from '../../components/adbanner'
import { Helmet } from "react-helmet"
import { AdContainer } from '../../components/adstyling'
import { MainBodySection } from '../../components/generalstyling'

// markup
const HistoryPage = () => {
    return (
      <Layout>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Opera History | The Opera 101</title>
            <html lang="en-us" />
            <link rel="canonical" href="https://theopera101.com/operaabc/history" />
            <meta name="description" content="A very brief opera history. Putting Baroque, Classical, Romantic and 20th Century opera in their places!" />
          </Helmet>
          <Hero title="Opera's History" subtitle="From Baroque to the present"/>
          <MainBodySection>

            <Header title="Overview"/>

            <p>Inevitably a short history of opera is going to leave a lot out. It’s an art form that has existed for
                  over 400 years and taken on many shapes and forms in a great many different cultures and nations. You
                  will however, frequently hear almost every Western opera categorised to a few periods which are
                  definitely a good place to start.</p>
          
            <p>Below, you'll find the core info on the periods with some key operas put in their chronological place. We hope to expand this section enormously, exploring opera's development across Europe and
                  the world, and we've started on that mission with a more expansive <Link to="/operaabc/history/english">history
                      of opera in English</Link>.</p>

            
            <div id="ezoic-pub-ad-placeholder-102"> </div>
            
            <Header title="Baroque"/>

            <p>The first musical theatre work that we might define as an opera today was Jacopo
                          Peri’s <i>Dafne</i>, composed in the late 1590s. Unfortunately little of Peri’s score survives so
                          Claudio Monteverdi’s <i>Orfeo</i> of 1607 takes the crown as the earliest work that you are able to
                          hear. Both these composers were working in Italy, and it was Italian opera that would dominate
                          what is now known as the Baroque period spanning from around 1600 to the 1740s. This form of
                          opera came to the fore in wealthy courts across Europe, royalty frequently patrons of composers,
                          but it rapidly became an art form that appealed to all classes, George Friedrich Handel’s work,
                          for example, wildly popular in England.</p>

            <p>Some of the major opera composers of this period were Antonio Vivaldi, Handel and Jean-Baptiste
                Lully. For much of the 20th Century, Baroque works were seldom performed but there has been
                something of a popularity boom over the last few decades. The H.I.P (Historically Informed
                Performance) movement pushing many of these works back into the repertory. Handel’s <em>Giulio
                    Cesare</em> and Henry Purcell’s <em>Dido and Aeneas</em> are two of the most commonly heard
                works today.</p>

            <Header title="Classical"/>

            <p>In the mid 1700s Willibald Christoph Gluck took opera in new directions, expanding the structure,
              harmony and narratives away from the highly formalised forms that had dominated the previous 150
              years. He made the orchestra more integral by developing “recitativo accompagnato”, recitative
              supported by full orchestra rather than just continuo. Opera became steadily more international
              and varied in style, Italian opera seria mixing with French opera comique and German singspiel
              amongst many other operatic genres.</p>

            <p>Some of the major opera composers were Gluck, Franz Joseph Haydn and Wolfgang Amadeus Mozart.
                Neither Gluck nor Haydn are all that frequently heard on modern stages but Mozart has an
                enormous number of works in the standard repertory, <Link to="/operas/giovanni">Don Giovanni</Link>, <em>The Magic
                    Flute</em> and <em>Cosi Fan Tutte</em> three of many.</p>

        </MainBodySection>


        <div id="ezoic-pub-ad-placeholder-103"> </div>
        
        <AdContainer>
          <AdBanner
            style={{ display: 'block' }}
            slot="3461570696"
            format="auto"
            responsive="true"
          />
        </AdContainer>

        <MainBodySection> 

            <Header title="Romantic"/>

            <p>Romantic opera has dominated operatic stages for the better part of two centuries. Emerging
                around the turn of the 19th century, Romanticism was the predominant artistic and literary
                movement until the 1st World War. As a movement it isn’t easily defined but it was born out of
                the French Revolution and Germany’s Sturm und Drang playing heavily towards strong emotions and
                a rebellion against the scientific conformity of the enlightenment and latterly the industrial
                revolution. Opera became steadily bigger and more dramatic, vast choruses and a swelled
                orchestra, to upwards of 100 players, building towards the immense operatic works of Richard
                Wagner.</p>

            <p>There are too many composers to mention here but Germany was dominated by Wagner, Italy by first <Link to="/composers/verdi">Giuseppe Verdi</Link> and then <Link to="/composers/puccini">Giacomo Puccini</Link> and Russia made its
                first real operatic impact with initially Mikhail Glinka and then Modest Mussorgsky and Pyotr
                Tchaikovsky amongst many others.</p>

            <Header title="20th Century"/>

            <p>More or less for the first time in operatic history, the 20th Century was dominated not by
                contemporary works but by those of the previous three hundred years. Few were writing new
                Romantic works but the old ones dominated the modern stage. It hasn’t been all doom and gloom
                for lovers of new music though, with sophisticated contemporary music making its way onto the
                operatic stages albeit sporadically and seldom popularly. Composers have become more inventive
                with the scoring, frequently using fewer orchestral players and creating more intimate dramas
                relative to the bombast of the Romantic period.</p>

            <p>The first half of the century was dominated by the modernists particularly Arnold Schoenberg and
                Alban Berg who developed atonal and then twelve-tone techniques (lots of dissonance used to
                chilling dramatic effect). Benjamin Britten and Dmitri Shostakovich came to the fore through the
                middle years of the 20th Century, Britten in particular arguably the most successful opera
                composer born after 1900. Minimalism came in full throttle by the 70s, Philip Glass and most
                recently John Adams the most successful composers in recent times.</p>

          </MainBodySection>
      </Layout>
  )
}

export default HistoryPage